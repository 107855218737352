.projects-project-image {
    border-radius: 3.5vw;
    width: 40vw;
    float: right;
    margin: 0 0 1vw 2.5vw;
}

@media only screen and (min-width: 1000px) {
    .projects-project-image {
        border-radius: 35px;
        width: 400px;
        margin: 0 0 10px 25px;
    }
}