.header {
    background-color: rgb(47, 137, 255);
    height: 25vmin;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (min-width: 1000px) {
    .header {
        max-height: 200px;
    }
}

@media only screen and (max-width: 600px){
    .header {
        flex-wrap: wrap;
        height: 25vw;
    }
}