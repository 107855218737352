.header-h2 {
    margin: 0;
    padding: 0;
    font-size: 3.5vw;
    font-weight: bolder;
    cursor: pointer;
}

@media screen and (max-width: 600px){
    .header-h2 {
        font-size: 4vw;
    }
}

@media screen and (min-width: 1000px){
    .header-h2 {
        font-size: 35px;
    }
}