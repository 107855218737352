.text-image-container-homepage {
    display: flex;
    width: 80%;
    align-items: center;
    white-space: pre-wrap;
}

@media screen and (min-width: 1000px){
    .text-image-container-homepage {
        max-width: 1000px;
    }
}