.go-up-button {
    width: 9vw;
    height: 9vw;
    border-radius: 2vw;
    position: fixed;
    right: 1vw;
    bottom: 2.5vw;
    background-color: rgb(255, 152, 27);
    display: flex;
    z-index: 2;
    opacity: 0;
    transition: 0.4s;
    align-items: center;
    justify-content: center;
}

.go-up-button:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.visible {
    opacity: 1;
    transition: 0.4s;
}

.go-up-button-image {
    width: 70%;
    height: 70%
}

@media screen and (max-width:600px) {
    .go-up-button {
        width: 14vw;
        height: 14vw;
        border-radius: 4vw;
        right: 1vw;
        bottom: 3vw;
    }
}

@media screen and (min-width: 1000px) {
    .go-up-button {
        max-width: 90px;
        max-height: 90px;
        border-radius: 20px;
        right: 10px;
        bottom: 25px;
    }
}
