.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 2vh 0;
    min-height: 850px;
}

@media screen and (max-width: 600px) {
    .contact {
        min-height: 750px;
    }
}