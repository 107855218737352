.projects-project-container {
    width: 85vw;
    height: auto;
    margin: 5vw;
    padding: 3vw 2.5vw 3vw 2.5vw;
    border-radius: 5vw;
    background-color: rgb(83, 192, 87);
}

@media only screen and (min-width: 1000px) {
    .projects-project-container {
        margin: 50px;
        padding: 30px 25px 30px 25px;
        border-radius: 50px;
        max-width: 850px;
    }
}