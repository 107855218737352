.projects-h2 {
    text-align: center;
    width: 90%;
    font-size: 2.3vw;
    margin: 3vw 0 10vw 0;
}


@media screen and (min-width: 1000px) {
    .projects-h2 {
        font-size: 24px;
        margin: 30px 0 100px 0;
    }
}

@media screen and (max-width: 600px){
    .projects-h2 {
        font-size: 3vw;
        margin: 3vw 0 10vw 0;
        max-width: 80%;
    }
}