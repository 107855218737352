.contact-h1 {
    width: 100%;
    text-align: center;
    font-size: 3.5vw;
    margin: 8vw 0 6vw 0;
}

@media screen and (max-width: 600px) {
    .contact-h1 {
        font-size: 5vw;
    }
}

@media screen and (min-width: 1000px) {
    .contact-h1 {
        font-size: 35px;
        margin: 80px 0 60px 0;
    }
}