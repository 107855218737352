.homepage-featured-tech-stack {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featured-tech-icon-container {
    width: 8vw;
    height: 8vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1vw 0 1vw;
}

.featured-tech-image {
    height: 60%;
    position: absolute;
}

.circlesvg-featured-tech {
    position: absolute;
    width: 100%;
    height: 100%;
    fill: white;
}

@media screen and (max-width: 600px) {
    .featured-tech-icon-container {
        width: 11vw;
        height: 11vw;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1vw 0 1vw;
    }
}

@media screen and (min-width: 1000px) {
    .featured-tech-icon-container {
        width: 80px;
        height: 80px;
        margin: 0 10px 0 10px;
    }
}