.social {
    width: 9vw;
    height: 9vw;
    background-color: white;
    margin: 1.2vmin;
    border-radius: 2vmin;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-link {
    margin: 0;
    padding: 0;
    width: 6vw;
    height: 6vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-link-image {
    margin: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
}

.social-link-image:hover {
    transition: transform 0.5s;
    transform: scale(1.1);
    cursor: pointer;
}

@media only screen and (min-width: 1000px) {
    .social {
        height: 90px;
        width: 90px;
        border-radius: 20px;
    }
    .social-link {
        height: 60px;
        width: 60px;
    }
}

@media only screen and (max-width: 600px) {
    .social {
        height: 12vw;
        width: 12vw;
        border-radius: 4vw;
    }
    .social-link {
        height: 8vw;
        width: 8vw;
    }

    .contact .social {
        height: 18vw;
        width: 18vw;
    }
    .contact .social-link {
        height: 12vw;
        width: 12vw;
    }
}