.homepage-featured-p1 {
    display: inline;
    font-size: 1.8vw;
    max-width: 90%;
    text-align: center;
}

.homepage-featured-internal-link {
    font-size: 1.8vw;
    font-weight: bolder;
    color: black;
}

@media screen and (max-width: 600px) {
    .homepage-featured-p1 {
        font-size: 3vw;
    }
    
    .homepage-featured-internal-link {
        font-size: 3vw;
    }
}

@media screen and (min-width: 1000px) {
    .homepage-featured-p1 {
        font-size: 18px;
    }
}

@media screen and (min-width: 1000px) {
    .homepage-featured-internal-link {
        font-size: 18px;
    }
}