.homepage-featured-h1 {
    margin: 0;
    text-align: center;
    max-width: 90%;
    font-size: 2.7vw;
}

@media screen and (max-width: 600px) {
    .homepage-featured-h1 {
        font-size: 4vw;
    }
}

@media screen and (min-width: 1000px) {
    .homepage-featured-h1 {
        font-size: 27px;
    }
}