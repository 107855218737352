.header-h1 {
    font-size: 4vw;
    font-weight: bolder;
    padding: 0;
    cursor: pointer;
}    

@media screen and (max-width: 600px){
    .header-h1 {
        font-size: 5vw;
    }
}

@media screen and (min-width: 1000px){
    .header-h1 {
        font-size: 40px;
    }
}