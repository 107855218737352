.App {
  position: relative;
  overflow: hidden;
}

.content {
  background-color: rgb(205, 244, 255);
  min-height: calc(100vh - 45vw);
}

.body {
  margin: 15px;
  padding: 0;
}


h1 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}