.about-h1 {
    width: 100%;
    text-align: center;
    font-size: 3.5vw;
    margin: 10vw 0 13vw 0;
}

@media screen and (max-width: 600px) {
    .about-h1 {
        font-size: 5vw;
        margin: 10vw 0 5vw 0;
        max-width: 80%;
    }   
}

@media screen and (min-width: 1000px) {
    .about-h1 {
        font-size: 35px;
        margin: 100px 0 130px 0;
    }   
}