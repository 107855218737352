.contact-h2 {
    text-align: center;
    font-size: 2.3vw;
    margin: 5vw 0 5vw 0;
}

@media screen and (min-width: 1000px) {
    .contact-h2 {
        font-size: 24px;
        margin: 50px 0 50px 0;
    }
}

@media screen and (max-width: 600px){
    .contact-h2 {
        font-size: 3vw;
    }
}