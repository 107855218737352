.about-tech-stack-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 0 5vw 0;
    padding: 5vw 0 5vw 0;
}


@media screen and (min-width: 750px) {
    .about-tech-stack-container {
        margin: 0 0 37.5px 0;
        padding: 37.5px 0 37.5px 0;
    }
}

@media screen and (min-width: 1000px) {
    .about-tech-stack-container {
        max-width: 1000px;
    }
}