.value-icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.66vw;
    height: 16.66vw;
    margin: 0 4vw 0 4vw;
}

.circlesvg-value {
    position: absolute;
    z-index: 1;
    fill: rgb(189, 83, 255);
    stroke-width: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
}

.about-icon-values {
     width: 24vw;
     height: 24vw;
}

@media screen and (max-width: 600px){
    .value-icon-container {
        width: 16.66vw;
        height: 16.66vw;
        margin: 0 4vw 0 4vw;
    }

    .about-icon-values {
        width: 24vw;
        height: 24vw;
   }
}

@media screen and (min-width: 1000px){
    .value-icon-container {
        width: 166px;
        height: 166px;
        margin: 0 40px 0 40px;
    }

    .about-icon-values {
        width: 240px;
        height: 240px;
    }
}