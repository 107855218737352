.about-value-name {
    width: 100%;
    text-align: center;
    margin: 0 0 2.5vw 0;
    opacity: 1;
    font-size: 2.5vw;
}

@media screen and (max-width: 600px){
    .about-value-name {
        font-size: 3vw;
    }
}

@media screen and (min-width: 1000px) {
    .about-value-name {
        margin: 0 0 25px 0;
        font-size: 25px;
    }
}