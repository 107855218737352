.featured-project-container {
    margin: 2vh 2vw 2vh 2vw;
    padding: 2vh 0 2vh 0;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh 2vw;
    background-color: rgb(83, 192, 87);
    border-radius: 5vw;
}

.featured-nav-link {
    display: inline;
    font-size: 1.8vw;
    text-align: center;
    backface-visibility: hidden;
    transition: transform 0.5s;
}

.featured-nav-link:hover {
    transition: transform 0.5s;
    transform: scale(1.1);
}

.social-links {
    display: flex;
    align-items: center;
}

@media screen and (min-width: 1500px) {
    .featured-project-container {
      margin: 5vh 2.5vw 2vh 2.5vw;
      width: 40vw;
      padding: 38.74px 0 38.74px 0;
    }
    .featured-nav-link {
        font-size: 16.866px;
    }
}


@media screen and (max-width: 600px){
    .featured-project-container {
        display: flex;
        justify-content: space-between;
        margin: 5vw 5vw 5vw 5vw;
        width: 90vw;
        height: 98vw;
        padding: 7vw 0 7vw 0;
        gap: 1vh 1vw;
        border-radius: 5vw;
      }
}