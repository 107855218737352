.about-h2 {
    width: 50%;
    text-align: center;
    font-size: 2.3vw;
    white-space: pre-wrap;
}

@media screen and (min-width: 1000px) {
    .about-h2 {
        font-size: 24px;
    }
}

@media screen and (max-width: 600px) {
    .about-h2 {
        font-size: 3vw;
    }
}