.interest-icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.66vw;
    height: 16.66vw;
    margin: 0 2.5vw 0 2.5vw;
}

.circlesvg-passion {
    z-index: 1;
    fill: rgb(255, 65, 65);
    opacity: 1;
    width: 100%;
    height: 100%;
}

.about-icon-passions {
     width: 24vw;
     height: 24vw;
}

@media screen and (max-width: 600px) {
    .interest-icon-container {
        width: 16.66vw;
        height: 16.66vw;
        margin: 0 2.5vw 0 2.5vw;
    }
    .about-icon-passions {
        width: 24vw;
        height: 24vw;
   }
}

@media screen and (min-width: 1000px) {
    .interest-icon-container {
        width: 166.6px;
        height: 166.6px;
        margin: 0 25px 0 25px;
    }

    .about-icon-passions {
        width: 240px;
        height: 240px;
    }
}