.featured {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 3vh 0;
    gap: 2vw 0;
}

@media screen and (max-width: 600px){
    .featured {
        gap: 5vw 0;
        /* padding: 0 0 5vh 0; */
    }
}