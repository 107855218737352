.projects-project-technicals {
    font-size: 2.3vw;
    margin: 4vw 2.5vw 3vw 2.5vw;
    white-space: pre-wrap;
}

@media only screen and (max-width: 600px) {
    .projects-project-technicals {
        font-size: 3vw;
        margin: 4vw 2.5vw 3vw 2.5vw;
        white-space: pre-wrap;
    }
}

@media only screen and (min-width: 1000px){
    .projects-project-technicals {
        font-size: 24px;
        margin: 40px 25px 30px 25px;
    }
}
