.tech-stack-icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13.66vw;
    height: 13.66vw;
    margin: 0 3vw 0 3vw;
}

.circlesvg-tech-stack {
    position: absolute;
    z-index: 1;
    fill: rgb(255, 255, 255);
    stroke-width: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
}

.about-icon-tech-stack {
     width: 20vw;
     height: 20vw;
 }

@media screen and (max-width: 600px){
    .tech-stack-icon-container {
        width: 16.66vw;
        height: 16.66vw;
        margin: 0 2.5vw 0 2.5vw;
    }

    .about-icon-tech-stack {
        width: 24vw;
        height: 24vw;
    }
}

@media screen and (min-width: 1000px){
    .tech-stack-icon-container {
        width: 136px;
        height: 136px;
        margin: 0 30px 0 30px;
    }

    .about-icon-tech-stack {
        width: 200px;
        height: 200px;
    }
}