.navbar-choices-container {
    width: 30vw;
    display: flex;
    justify-content: center;
}

.screen-fade-off {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
}

.screen-fade-on {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: rgb(200, 234, 255);
    z-index: 2;
}

.navbar-choices-icon {
    position: relative;
    opacity: 1;
    z-index: 2;
    width: 50%;
    max-width: 105px;
    border-radius: 50%;
    transition: 0.4s;
}

.navbar-choices-icon:hover {
    background-color: rgb(110, 173, 255);
    transition: 0.4s;
    cursor: pointer;
    transform: scale(1.1);
}

.navbar-choices-dropdown-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2vw 0;
    right: 0;
    top: 25vmin;
    margin: 2vw 0 0 0;
    width: 33vw;
    max-width: 250px;
    transition: 0.4s;
    opacity: 1;
    z-index: 2;
}

.navbar-choices-dropdown-container-hidden {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2vw 0;
    right: 0px;
    top: 25vmin;
    margin: 2vw 0 0 0;
    transition-delay: 0.6s;
    width: 33vw;
    max-width: 300px;
    opacity: 1;
    z-index: -1;
}

.navbar-choices-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    text-decoration: none;
    font-size: 4vw;
    font-weight: bolder;
    border-radius: 10px 0 0 10px;
    z-index: 1;
    width: 100%;
    height: 14.5vw;
    max-height: 110px;
    background-color: rgb(47, 137, 255);
    transition: 0.4s;
    opacity: 1;
    z-index: 2;
}

.navbar-choices-link-hidden {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    text-decoration: none;
    font-size: 4vw;
    border-radius: 10px 0 0 10px;
    z-index: 1;
    width: 100%;
    height: 14.5vw;
    max-height: 110px;
    position: relative;
    right: 0;
    transform: translateX(350px);
    background-color: rgb(47, 137, 255);
    transition: 0.4s;
    opacity: 1;
    z-index: 2;
}

.navbar-choices-link:hover {
    background-color: rgb(110, 173, 255);
    transition: 0.4s;
}

@media screen and (max-width: 600px) {
    .navbar-choices-dropdown-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 2vw 0;
        right: 0;
        top: 25vmin;
        margin: 2vw 0 0 0;
        width: 33vw;
        overflow: hidden;
        transition: 0.4s;
    }

    .navbar-choices-dropdown-container-hidden {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 2vw 0;
        right: 0;
        top: 25vw;
        margin: 2vw 0 0 0;
        transition: 0.4s;
        width: 33vw;
        overflow: hidden;
        transform: translateX(33vw);
        padding-left: 0;
        /* transition: 0.4s; */
        transition-delay: 0.6s;
    }
    
    .navbar-choices-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        text-decoration: none;
        /* font-size: 4vw; */
        border-radius: 2.667vw 0 0 2.667vw;
        z-index: 1;
        width: 33vw;
        height: 14.5vw;
        background-color: rgb(47, 137, 255);
        transition: 0.4s;
    }

    .navbar-choices-link-hidden {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        text-decoration: none;
        /* font-size: 4vw; */
        border-radius: 2.667vw 0 0 2.667vw;
        z-index: 1;
        width: 33vw;
        height: 14.5vw;
        right: 0;
        transform: translateX(350px);
        background-color: rgb(47, 137, 255);
        transition: 0.4s;
    }

    .navbar-choices-icon:hover {
        background-color: rgb(110, 173, 255);
        transition: 0.4s;
        cursor: pointer;
    }
}

@media screen and (min-width: 800px){
    .navbar-choices-link {
        font-size: 24px;
    }

    .navbar-choices-link-hidden {
        font-size: 24px;
    }
}

@media screen and (min-width: 1300px){

}

.unclickable {
    pointer-events: none;
}