.featured-image {
    height: 26.8799vw;
    width: 48vw;
    border-radius: 1.5vh; 
}


@media screen and (min-width: 1500px) {
    .featured-image {
      height: 308px;
      width: 550px;
    }
}

@media screen and (max-width: 600px) {
  .featured-image {
    width: 76vw;
    height: 42.56vw;
  }
}