.footer {
    background-color: rgb(47, 137, 255);
    width: 100%;
    height: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 1000px) {
    .footer {
        height: 140px;
    }
}

@media screen and (max-width: 600px){
    .footer {
        height: 20vw;
    }
}