.about-tech-stack-icon {
    width: 65%;
    position: absolute;
    opacity: 1;
    z-index: 1;
    transition: 0.5s;
}

.about-tech-stack-icon:hover {
    transform: scale(1.05);
    transition: 0.5s;
}
