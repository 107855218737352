.projects-h1 {
    margin: 10vw 0 3vw 0;
    text-align: center;
    width: 90%;
    font-size: 3.5vw;
}

@media screen and (max-width: 600px){
    .projects-h1 {
        font-size: 5vw;
        margin: 10vw 0 5vw 0;
        max-width: 80%;
    }
}

@media screen and (min-width: 1000px) {
    .projects-h1 {
        margin: 100px 0 30px 0;
        font-size: 35px;
    }
}