.homepage-h2 {
    text-align: center;
    width: 80%;
    font-size: 2.3vw;
    font-weight: lighter;
    margin: 3vw 0 3vw 0;
}


@media screen and (min-width: 1000px) {
    .homepage-h2 {
        margin: 30px 0 30px 0;
        font-size: 24px;
        max-width: 1000px;
    }
}

@media screen and (max-width: 600px){
    .homepage-h2 {
        font-size: 3vw;
        margin: 3vw 0 3vw 0;
    }
}