.projects-project-description {
    font-size: 2.3vw;
    margin: 0 2.5vw 6vw 2.5vw;
}

@media only screen and (max-width: 600px) {
    .projects-project-description {
        font-size: 3vw;
        margin: 0 2.5vw 6vw 2.5vw;
    }
}

@media screen and (min-width: 1000px){
    .projects-project-description {
        font-size: 24px;
        margin: 0 25px 60px 25px;
    }
}